<template>
  <div class="Recharge">
    <!-- 头部 -->

    <div class="topbar" v-if="token == null" style="text-align: left;">
      <img @click="$router.push('/UserInfo')" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
      <div class="head-title">{{ $t("儲值") }}</div>
    </div>

    <!-- 活动横幅 -->
    <div class="activity">
      <!-- <p class="pa-0 ma-0" style="font-size: 16px">
        7/25 - 8/7 {{ $t("官網直充點數") }}
        <span style="color: #f84279; font-weight: 700"
          >{{ $t("加贈") }}+5%</span
        >
      </p> -->
      <p class="pa-0 ma-0" style="font-size: 16px">
        {{ $t("充值獲得的點數可直接在遊戲內兌換道具") }}
      </p>
    </div>
    <div class="content" id="content">
      <h3 class="pa-2">{{ $t("請選擇儲值類型") }}</h3>
      <!-- 充值方法小于、等于五条 -->
      <div v-if="methods.length <= methodCount">
        <div class="select" v-if="PayName && PayName.length">
          <v-select
            v-model="select"
            hide-details=""
            :items="PayName"
            :label="$t('請選擇儲值類型')"
            item-value="id"
            item-text="name"
            @change="choiceType(select.id)"
            outlined
            return-object
          ></v-select>
        </div>
      </div>
      <!-- 充值方法大于五条 -->
      <div v-else class="classify">
        <div class="classify-header">
          <ul>
            <li
              v-for="item in methodType"
              :key="item.id"
              @click="changeRechargeType(item.id)"
              :class="[active1 == item.id ? 'active' : '']"
            >
              <div class="payIcon"><img :src="item.img" alt="" /></div>
              <div class="payName">{{ item.name }}</div>
            </li>
          </ul>
        </div>
        <div class="classify-content">
          <!-- 展示通道 -->
          <ul v-if="eachMethods.length" ref="classifyContentUl">
            <li>
              <v-radio-group class="ma-0" v-model="radioGroup" hide-details>
                <v-radio
                  v-for="(item, index) in eachMethods"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @change="choiceType(item.id)"
                ></v-radio>
              </v-radio-group>
            </li>
          </ul>
          <!-- 没有通道展示图片 -->
          <ul v-else>
            <li>
              <img
                style="height: 180px"
                src="../assets/imgs/noChannel.png"
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
      <!--选择充值金额 -->
      <h3 class="pa-2" v-if="is_fast || is_custom">{{ fastName }}</h3>
      <div class="chioce" v-if="is_fast">
        <ul>
          <li
            v-for="(item, index) in chioceMoney"
            :key="index"
            :class="[active == index ? 'active' : '']"
            @click="chioceRmb(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <v-text-field
        v-if="is_custom"
        v-model="RMB"
        color="info"
        :placeholder="$t('自定義儲值金額')"
        outlined
        clearable
        :oninput="`if(value.length>${
          (maxmub + '').length + 2
        }) value=value.slice(0,${(maxmub + '').length + 2})`"
        type="number"
        class="rounded-lg mt-4"
        onkeyup="value=value.replace('.', '')"
        @input="search()"
        @focus="inputRmb()"
      ></v-text-field>
      <!-- :oninput="`if(value>${this.maxmub})value=${this.maxmub};if(value<${this.minimub})value=${this.minimub}`" -->
      <div class="tips" v-if="(is_fast || is_custom) && amount">
        {{ zhifuName }} {{ (amount * discount).toFixed(2) }}
        <span v-if="type == !10000">{{ $t("可兌換點數") }}</span>
        <span v-else>{{ $t("可兌換點數") }}</span
        >{{ count }}
        <span v-if="rate > 1" style="color: #f00"
          >+{{ ((rate - 1) * count).toFixed(2) }}</span
        >
      </div>
      <v-btn
        v-if="is_fast || is_custom"
        height="60"
        block
        class="rounded-lg mt-1 btn1"
        @click="openDialog"
        bottom
      >
        {{ $t("確定") }}
      </v-btn>
    </div>

    <!-- 弹出框 -->
    <v-dialog v-model="dialog1" persistent>
      <div class="c" >
        <div class="c1">{{ $t("儲值訂單確認") }}</div>
        <div class="c2" v-if="select">{{ $t("付款類型") }}: {{ select.name }}</div>
        <div class="c2" v-if="radioGroup">  {{ $t("付款類型") }}: {{
              eachMethods.filter((item) => item.id == radioGroup)[0].name
            }}</div>
        <div class="c3">{{ rechargeTypeName }}: {{ (amount * discount).toFixed(2) }}</div>
        <div class="c4">{{ $t("到賬點數") }}: {{ (count * rate).toFixed(2) }}</div>
        <div class="c5"></div>
        <div class="c6">
          <div @click="dialog1 = false">{{ $t("再想想") }}</div>
          <div  @click="submit()">{{ $t("送出資料") }}</div>
        </div>
      </div>
    </v-dialog>
    <Dialog ref="hintDialog" />
  </div>
</template>

<script>
import i18n from "@/i18n/index";
export default {
  data() {
    return {
      radioGroup: null,
      recommendIcon: require("@/assets/imgs/recharge-icon1.png"),
      fastName: i18n.t("請選擇儲值金額(人民幣)"),
      rechargeTypeName: i18n.t("儲值金額"),
      zhifuName: i18n.t("支付"),
      // 快速充值
      is_fast: null,
      // 自定义充值
      is_custom: null,
      select: null,
      dialog1: false,
      active: null,
      active1: 0,
      token: null,
      discount: 1,
      type: 0,
      is_sugoi: false,
      token: null,
      RMB: "",
      // 展示的点数
      count: null,
      // 金额
      amount: null,
      // 支付方法
      PayName: null,
      // 选择金额
      chioceMoney: [],
      // 最小金额
      minimub: null,
      // 最大金额
      maxmub: null,
      // 金额点数比例
      rate: null,
      //所有充值方法
      methods: [],
      // 充值方法个数
      methodCount: null,
      // 充值方法分类
      methodType: [],
      // 储存每个分类的充值方法
      eachMethods: [],
    };
  },
  created() {
    var appToken = this.getQueryString("appToken");
    if (appToken) {
      this.$get("/api/account/getNewToken", {
        token: appToken,
        gameId: process.env.VUE_APP_API_BASE_GameId,
      }).then((res) => {
        localStorage.setItem("token", res.data.token);
        // 获取充值通道
        this.getPayMethods();
      });
    } else {
      // 获取充值通道
      this.getPayMethods();
    }
    //判断get参数token
    this.token = this.$route.query.token;
    if (this.token) {
      localStorage.setItem("token", this.token);
    }
  },
  methods: {
    // 获取默认推荐的充值方法
    getDefaultRechargeType() {
      if (this.active1 == 0) {
        var arr = [];
        this.methods.map((item) => {
          if (item.is_recommend) {
            arr.push(item);
          }
        });
        this.eachMethods = arr;
      }
    },
    // 充值分類選擇
    changeRechargeType(id) {
      this.active1 = id;
      this.radioGroup = null;
      this.RMB = null;
      this.is_fast = false;
      this.is_custom = false;
      if (id == 0) {
        var arr = [];
        this.methods.map((item) => {
          if (item.is_recommend) {
            arr.push(item);
          }
        });
        this.eachMethods = arr;
      } else {
        var arr = [];
        this.methods.map((item) => {
          if (item.method_type == id) {
            arr.push(item);
          }
        });
        this.eachMethods = arr;
      }
    },
    getQueryString(name) {
      var value;
      var url = window.location.href;
      var pairs = url.split("?");
      if (pairs.length < 2) return value;
      pairs = pairs[pairs.length - 1].split("&");
      if (pairs.length < 1) return value;
      pairs.forEach((element) => {
        var pair = element.split("=");
        var key = pair[0];
        if (key.toLowerCase() == name.toLowerCase()) {
          value = pair[1];
          return;
        }
      });
      return value;
    },
    search() {
      this.amount = this.RMB;
      this.count = this.RMB;
    },
    chioceRmb(item, idx) {
      this.RMB = "";
      this.active = idx;
      this.amount = item;
      if (this.is_sugoi) {
        this.getSugoiAmount();
        return;
      }
      this.count = this.amount;
    },
    inputRmb() {
      this.active = null;
      this.amount = this.RMB;
      this.count = this.RMB;
    },
    choiceType(id) {
      this.methods.map((item) => {
        if (item.id == id) {
          this.name = item.name;
          this.chioceMoney = item.fast.split(",");
          this.is_fast = item.is_fast;
          this.is_custom = item.is_custom;
          this.minimub = item.min;
          this.maxmub = item.max;
          this.rate = item.rate;
          this.discount = item.discount;
          this.count = Math.ceil(item.amount * item.rate);
          this.amount = Math.ceil(item.amount * item.discount);
          this.active = null;
          this.type = item.type;
          //判斷是不是點卡通道
          if (this.type == 10000) {
            this.fastName = i18n.t("請選擇點卡面額");
            this.rechargeTypeName = i18n.t("點卡面額");
            this.zhifuName = i18n.t("面額");
            this.is_sugoi = true;
          } else {
            this.is_sugoi = false;
            this.rechargeTypeName = i18n.t("儲值金額");
            this.fastName = i18n.t("請選擇儲值金額");
            this.zhifuName = i18n.t("支付");
          }
        }
      });
      // 选择充值方法自动定位
      this.$nextTick(() => {
        var content = document.querySelector("#content");
        content.scroll({
          top: content.scrollHeight,
          behavior: "smooth",
        });
      });
    },
    // 获取充值通道
    getPayMethods() {
      this.$getpay("/api/Home/PayMethods", {
        gameId: process.env.VUE_APP_API_BASE_GameId,
      }).then((res) => {
        if (res.code == 0) {
          this.methodCount = res.data.methodCount;
          this.methods = res.data.methods;
          this.methodType = res.data.methodType;
          var aa = [];
          var bb = [];
          var index = [];
          this.methodType.forEach((item) => {
            aa.push(item.id);
          });
          this.methods.forEach((item) => {
            bb.push(item.method_type);
          });
          // console.log(aa,bb);
          let differentArr = aa.filter((item, i) => {
            if (bb.indexOf(item) == -1) {
              index = i;
            }
          });
          // console.log(index);
          if (index) {
            index.forEach((item) => {
              this.methodType.splice(this.methodType.indexOf(item), 1);
            });
          }
          this.methodType.unshift({
            id: 0,
            img: this.recommendIcon,
            name: i18n.t("推薦"),
          });

          // 获取默认推荐的充值方法
          this.getDefaultRechargeType();
          let arr = [];
          res.data.methods.forEach((item) => {
            let obj = {
              name: "",
              id: "",
            };
            obj.name =
              item.discount == 1
                ? item.name
                : item.name + "(" + item.discount * 10 + i18n.t("折") + ")";
            obj.id = item.id;
            arr.push(obj);
          });
          this.PayName = arr;
        }
      });
    },
    // 获取sugoi金額
    getSugoiAmount() {
      this.$getpay("/api/Home/GetSugoiPoint", {
        sugoi_point: this.amount,
      }).then((res) => {
        if (res.code == 0) {
          this.amount = res.data.sugoi_point;
          this.count = res.data.amount;
        }
      });
    },
    openDialog() {
      if (!this.amount) {
        this.$toast(i18n.t("請選擇儲值金額"));
        return;
      } else {
        if (this.amount < this.minimub) {
          this.$toast(i18n.t("最小充值金額") + ":" + this.minimub);
          return;
        }
        if (this.amount > this.maxmub) {
          this.$toast(i18n.t("最大充值金額") + ":" + this.maxmub);
          return;
        }
        this.dialog1 = true;
        // if (!this.select && !this.amount) {
        //   this.$toast(i18n.t("請選擇儲值類型與金額"));
        // } else {
        //   if (!this.select) {
        //     this.$toast(i18n.t("請選擇儲值類型"));
        //   }
        //   if (!this.amount) {
        //     this.$toast(i18n.t("請選擇儲值金額"));
        //   }
        // }
      }
    },
    submit() {
      this.dialog1 = false;
      //type小于10000 开新窗口
      if (this.type < 10000) {
        var newTab = window.open("about:blank"); // 先打开页面
      }
      if (this.select) {
        this.$postpay("/api/Home/Recharge", {
          method: this.select.id,
          amount: this.amount * this.discount,
          gameId: process.env.VUE_APP_API_BASE_GameId,
        }).then((res) => {
          if (res.code == 0) {
            switch (res.data.type) {
              case 1:
                //返回url客户端跳转
                if (res.data.pay_url) {
                  newTab.location = res.data.pay_url;
                  this.returnOrder();
                  return;
                }
                if (res.data.qr_url) {
                  newTab.location = res.data.qr_url;
                  this.returnOrder();
                  return;
                }
                break;
              case 5:
              case 6:
                //返回参数客户端post
                this.httpPost(res.data.pay_url, JSON.parse(res.data.data));
                return;
                break;
              default:
                newTab.close();
                this.$toast(i18n.t("儲值通道暫時不可用"));
            }
          }
        });
      }
      if (this.radioGroup) {
        this.$postpay("/api/Home/Recharge", {
          method: this.radioGroup,
          amount: this.amount * this.discount,
          gameId: process.env.VUE_APP_API_BASE_GameId,
        }).then((res) => {
          if (res.code == 0) {
            switch (res.data.type) {
              case 1:
                //返回url客户端跳转
                if (res.data.pay_url) {
                  newTab.location = res.data.pay_url;
                  this.returnOrder();
                  return;
                }
                if (res.data.qr_url) {
                  newTab.location = res.data.qr_url;
                  this.returnOrder();
                  return;
                }
                break;
              case 5:
              case 6:
                //返回参数客户端post
                this.httpPost(res.data.pay_url, JSON.parse(res.data.data));
                return;
                break;
              default:
                newTab.close();
                this.$toast(i18n.t("儲值通道暫時不可用"));
            }
          }
        });
      }
    },
    returnOrder() {
      this.$refs.hintDialog.dialog2 = true;
      this.$refs.hintDialog.text1 = i18n.t("儲值完成");
      this.$refs.hintDialog.text2 = i18n.t("請進入遊戲再次點擊消費");
      this.$refs.hintDialog.skipOrder = "/Order";
    },
    //发送POST请求跳转到指定页面
    httpPost(url, params) {
      var temp = document.createElement("form");
      temp.action = url;
      temp.method = "post";
      //temp.target = "_blank"; //1.跳转新窗口需要设置该参数 2.当前页跳转可以去掉该参数
      temp.style.display = "none";
      for (var x in params) {
        var opt = document.createElement("input");
        opt.setAttribute("name", x);
        opt.setAttribute("value", params[x]);
        temp.appendChild(opt);
      }
      document.body.appendChild(temp);
      temp.submit();
      return temp;
    },
  },
};
</script>

<style lang="scss" scoped>
.Recharge {
  height: 100%;
  overflow: auto;
  margin-top: 46px;
  .topbar {
    display: flex;
    align-items: center;
    background-color: #F0F6F9;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }

  .activity {
    margin-top: 30px;
    padding: 10px 24px;
    background: #f4f4f4;
    text-align: left;
  }
  .content {
    height: calc(100% - 80px);
    overflow: auto;
    padding: 0 24px 30px;
    h3 {
      font-size: 18px;
      text-align: left;
    }
    .classify {
      .classify-header {
        ul {
          padding: 0;
          display: flex;
          background: #f8f8f8;
          border-radius: 10px;
          flex-wrap: wrap;
          li {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            position: relative;
            &:nth-child(5n) {
              &::after {
                content: none;
              }
            }
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              width: 1px;
              height: 10px;
              background-color: #bababa;
            }
            .payIcon {
              img {
                display: block;
                height: 20px;
                width: 20px;
              }
              margin-bottom: 5px;
            }
            .payName {
              font-size: 14px;
            }
          }
          .active {
            background: #f74077;
            border-radius: 10px;
            color: #fff;
          }
        }
      }
      .classify-content {
        overflow: auto;
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(124, 124, 124, 0.1);
        border-radius: 0px 0px 10px 10px;
        ul {
          -webkit-overflow-scrolling: touch;
          padding: 5px 20px;

          li {
            .v-input {
              .v-input__control {
                .v-input__slot {
                  .v-input--radio-group__input {
                    .v-radio {
                      flex-direction: row-reverse;
                      height: 40px;
                      border-bottom: 1px solid #eeeeee;
                      .v-input--selection-controls__input {
                        .v-input--selection-controls__ripple::before {
                          content: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .chioce {
      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // justify-content: space-between;
        padding: 0;
        li {
          width: 24%;
          margin-top: 5px;
          padding: 15px 30px;
          border: 1px solid #c1c1c1;
          border-radius: 10px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #999999;
          &:not(:nth-child(4n)) {
            margin-right: calc(4% / 3);
          }
        }
      }
      .active {
        border: 2px solid #f85489;
        color: #f85489;
      }
    }
    .tips {
      font-size: 18px;
    }
    .v-btn {
      background: linear-gradient(91.69deg, #f84077 16.39%, #f95e93 139.39%);
      box-shadow: 0px 15px 20px rgba(245, 86, 136, 0.2);
      color: #fff;
      font-size: 16px;
    }
  }
}
.c{
  background: #F0F6F9;
    box-shadow: 0 0.66667rem 0.93333rem rgb(89 100 104 / 20%);
    border-radius: 0.53333rem;
    text-align: left;
  .c1{
    font-size: 0.42667rem;
    line-height: 0.90667rem;
    color: #F84E84;
    padding: 0.66667rem 1.17333rem 0.32rem;
  }
  .c2,.c3,.c4{    
    padding: 0 1.17333rem;
    font-size: 0.37333rem;
    line-height: 1.33333rem;
    color: #485573;
  }
  .c5{
    border: 1px solid rgba(159, 184, 197, 0.5);
  }
  .c6{
    display: flex;
    justify-content: space-evenly;
    padding: 0.55333rem 0;
    >:nth-child(1){
      display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 40%;
    height: 1.4rem;
    background: #9FB8C5;
    box-shadow: 0 0.4rem 0.8rem rgb(159 184 197 / 20%);
    border-radius: 0.26667rem;
    font-size: 0.42667rem;
    line-height: 0.53333rem;
    text-align: right;
    color: #FFFFFF;
    cursor: pointer;
    }
    >:nth-child(2){
      display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 40%;
    height: 1.4rem;
    background: #F84E84;
    box-shadow: 0 0.4rem 0.8rem rgb(245 86 136 / 10%);
    border-radius: 0.26667rem;
    font-size: 0.42667rem;
    line-height: 0.53333rem;
    text-align: right;
    color: #FFFFFF;
    cursor: pointer;
    }
  }
}
</style>